<template>
  <div class="grid grid-cols-1 gap-3">
    <div class="col-span-1">
      <div class="grid grid-cols-2 gap-3">
        <div class="grid col-span-1">
          <router-link
            class="flex space-x-3 items-center border p-5 rounded-lg hover:bg-gray-200"
            :to="{ name: 'tagihan-maba-create' }"
          >
            <div>
              <CalendarRange
                class="text-theme-1 dark:text-white block w-6 h-6 mx-auto"
              />
            </div>
            <div>Buat Tagihan MABA</div>
          </router-link>
        </div>
        <div class="grid col-span-1">
          <router-link
            class="flex space-x-3 items-center border p-5 rounded-lg hover:bg-gray-200"
            :to="{ name: 'tagihan-maba-create-new' }"
          >
            <div>
              <CalendarRange
                class="text-theme-11 dark:text-white block w-6 h-6 mx-auto"
              />
            </div>
            <div>Buat Tagihan MABA (v2)</div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="col-span-1">
      <div class="grid grid-cols-2 gap-3">
        <div class="grid col-span-1">
          <router-link
            class="flex space-x-3 items-center border p-5 rounded-lg hover:bg-gray-200"
            :to="{ name: 'tagihan-uk-create' }"
          >
            <div>
              <CalendarRange
                class="text-theme-1 dark:text-white block w-6 h-6 mx-auto"
              />
            </div>
            <div>Buat Tagihan UK</div>
          </router-link>
        </div>
        <div class="grid col-span-1">
          <router-link
            class="flex space-x-3 items-center border p-5 rounded-lg hover:bg-gray-200"
            :to="{ name: 'tagihan-uk-cicilan-create' }"
          >
            <div>
              <BallotCheck
                class="text-theme-1 dark:text-white block w-6 h-6 mx-auto"
              />
            </div>
            <div>Buat Tagihan Cicilan UK</div>
          </router-link>
        </div>
      </div>
    </div>

    <router-link
      :to="{ name: 'tagihan-sp-create' }"
      class="flex space-x-3 items-center border p-5 rounded-lg hover:bg-gray-200"
    >
      <div>
        <CalendarRange class="text-theme-1 dark:text-white block w-6 h-6" />
      </div>
      <div>Buat Tagihan SP</div>
    </router-link>

    <router-link
      :to="{ name: 'tagihan-lainnya-permhs' }"
      class="flex space-x-3 items-center border p-5 rounded-lg hover:bg-gray-200"
    >
      <div>
        <CalendarRange class="text-theme-1 dark:text-white block w-6 h-6" />
      </div>
      <div>Buat Tagihan Keuangan Lainnya (Per Mahasiswa)</div>
    </router-link>
  </div>
</template>

<script>
import CalendarRange from "../../../assets/icons/calendarRange";
import UserCheck from "../../../assets/icons/userCheck";
import CalendarCheck from "../../../assets/icons/calendarCheck";
import BallotCheck from "../../../assets/icons/ballotCheck";
export default {
  name: "LayoutMenuBiayaKuliah",
  components: { BallotCheck, CalendarCheck, UserCheck, CalendarRange },
};
</script>

<style scoped></style>
